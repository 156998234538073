if ('serviceWorker' in navigator) {
    /*
     * Delay registration until after the page has loaded, to ensure that our
     * precaching requests don't degrade the first visit experience.
     * @link https://developers.google.com/web/fundamentals/instant-and-offline/service-worker/registration
     */
    window.addEventListener('load', function() {
        /*
         * Your service-worker.js *must* be located at the top-level directory relative to your site.
         * It won't be able to control pages unless it's located at the same level or higher than them.
         * Don't register service worker file in, e.g., a scripts/ sub-directory!
         * @link https://github.com/slightlyoff/ServiceWorker/issues/468
         */
        navigator.serviceWorker.register('/service-worker.js')
            .then(function(reg) {
                // `updatefound` is fired if service-worker.js changes.
                reg.onupdatefound = function() {
                    /*
                     * The `updatefound` event implies that reg.installing is set
                     * @link https://w3c.github.io/ServiceWorker/#service-worker-registration-updatefound-event
                     */
                    var installingWorker = reg.installing;

                    installingWorker.onstatechange = function() {
                        switch (installingWorker.state) {
                            case 'installed':
                                // The service worker has been installed
                                break;

                            case 'redundant':
                                // The installing service worker became redundant
                                break;
                            default:
                                break;
                        }
                    };
                };
            })
            .catch(function(e) {
                console.error('Error during service worker registration:', e);
            });
    });
}
